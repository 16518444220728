import React from "react";

const Navbar = () => {
    return (
        <nav class="navbar-menu" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <a class="navbar-item" href="https://www.linkedin.com/in/cgwdev/" target="_blank" rel="noopener noreferrer">
                    Claiton Watthier - Software Developer
                </a>
            </div>

            <div id="navbarBasicExample" class="navbar-menu">
                <div class="navbar-end">
                    <a class="navbar-item" href="/#">
                        Newsletter
                    </a>

                    <a class="navbar-item" href="/#">
                        Blog
                    </a>

                    <a class="navbar-item" href="/#">
                        Social
                    </a>

                    <a class="navbar-item" href="/#">
                        About
                    </a>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;