import React from 'react';
import 'bulma/css/bulma.min.css';
import Navbar from './components/home/Navbar';

function App() {
  return (
    <div class="main">
      <div class="columns">
        <div class="column auto"></div>
        <div class="column is-four-fifths">
          <Navbar />
          <section class="hero is-info is-small is-family-sans-serif">
            <div class="hero-body">
                <p class="title is-family-monospace">
                  Hi, <br/> I'm Claiton Watthier.
                </p>
                <p class="subtitle">
                  This is my website. It's a pleasure to have your visit here.
                </p>
            </div>
          </section>

          <section class="hero is-small  has-text-centered">
            <div class="hero-body">
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbd6a4I34W-VLPwsuvu239PNBJyePj6jc1Wg&usqp=CAU' alt='Building pages'/>
            </div>
          </section>

          <footer class="footer is-small">
            <div class="content has-text-centered">
              <p>
                This site is still being created, go slowly.
              </p>
              <p>
                The aim is to use this place as my own sandbox to explore some technologies.
              </p>
              <p>
                If I have enough time I would like to create some tutorials in a blog format. The newsletter is something for the future.
              </p>
            </div>
          </footer>
        </div>
        <div class="column auto"></div>
      </div>
    </div>
  )
}

export default App;